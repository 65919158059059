<template>
  <div v-if="dialogUpdateStream">
    <v-dialog :value="dialogUpdateStream" persistent transition="dialog-bottom-transition">
      <v-card >
        <v-toolbar >
          <v-btn icon  @click="$emit('closeDialogUpdateStream')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-uppercase">
            Update Stream
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :disabled="loading"  class="btn btn-warning" @click="validate">
              Update
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <Loading :visible="loading"/>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
             <v-col cols="12">
                <v-text-field
                  v-model="selectedUpdateChanel.live_urls[0]"
                  label="Current Url"
                  :rules="rules.url_1"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="selectedUpdateChanel.live_urls[1]"
                  label="Change Url"
                  :rules="rules.url_2"
                />
              </v-col>
              <v-row>
                <v-col cols="6">
                  <v-select :items="[false, true]" v-model="selectedUpdateChanel.is_maintain" color="warning" label="Maintanance status" />
                </v-col>
                <v-col cols="6">
                  <v-select :items="[false, true]" v-model="selectedUpdateChanel.is_stop_video" color="warning" label="Video Status" />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialogUpdateStream: {
      type: Boolean,
      default: false
    },
    selectedUpdateChanel: {
      type: Array 
    },
    loading: {
      type: Boolean
    },
    rules: {
      type: Object,
      default: function () {
        return {
          url_1: [
            v => !!v || 'Input url is required',
          ],
          url_2: [
            v => !!v || 'Input url is required',
          ],
        }
      }
    },
    isValidate: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate
      },
      // setter
      set: function (newValue) {
        this.$emit('update:isValidate', newValue)
      }
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('submit', this.selectedUpdateChanel)
      }
    }
  }
}

</script>
